body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

/* BUBBLE */
.bubble {
  background: #fff;
  float: left;
  border: 1px solid #d0cfd2;
  padding: 15px;
  box-shadow: 0px 0px 2px #000;
  border-radius: 3px;
  height: auto;
  width: 324px;
  margin: 50px 150px;
  position: relative;
}

.bubble:after {
  content: "";
  position: absolute;
  border: 1px solid rgba(51, 51, 51, 0.19);
}

.bubble:before {
  content: "";
  position: absolute;
  border: 1px solid #333;
}
/*triangle side*/

/*top*/
.bubble.top:after {
  border-color: white transparent;
  border-width: 0 13px 12px 13px;
  top: -12px;
  left: 45%;
}

.bubble.top:before {
  border-color: #999 transparent;
  border-width: 0 13px 11px 13px;
  top: -13px;
  left: 45%;
}

/*bottom*/
.bubble.bottom:after {
  border-color: white transparent;
  border-width: 12px 13px 0 13px;
  bottom: -12px;
  left: 45%;
}

.bubble.bottom:before {
  border-color: #999 transparent;
  border-width: 11px 13px 0 13px;
  bottom: -13px;
  left: 45%;
}

/*right*/
.bubble.right:after {
  border-color: transparent white;
  border-width: 13px 0 13px 12px;
  top: 35%;
  right: -12px;
}

.bubble.right:before {
  border-color: transparent #999;
  border-width: 13px 0 13px 11px;
  top: 35%;
  right: -13px;
}

/*left*/
.bubble.left:after {
  border-color: transparent white;
  border-width: 13px 12px 13px 0;
  top: 35%;
  left: -12px;
}

.bubble.left:before {
  border-color: transparent #999;
  border-width: 13px 11px 13px 0;
  top: 35%;
  left: -13px;
}

/* PAGINATION */
.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #000;
  color: #000;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: rgba(26, 102, 210, 0.256);
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}